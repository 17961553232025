.contact-container {
  background-color: $fourthBgColor;
  height: 80vh;
  display: flex;
  flex-direction: column;

  @include forTablet {
    height: 60vh;
  }

  @include forPc {
    height: 55vh;
  }

  & h2 {
    font-size: 1rem;
    font-family: $menuFont;
    text-align: right;
    word-spacing: 10px;
    letter-spacing: 2px;
    padding-right: 8vw;
    padding-top: 10vh;

    @include forTablet {
      font-size: 1rem;
      padding-top: 8vh;
    }

    @include forPc {
      font-size: 1.3rem;
    }
  }

  & .contact-container_contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8vh;
    padding-left: 20vw;
    padding-right: 20vw;

    @include forTablet {
      padding-top: 8vh;
    }

    @include forPc {
      padding-top: 0vh;
    }

    & .contact-container_title {
      font-family: $mainFont;
      font-size: 1.6rem;
      padding-bottom: 5vh;
      padding-top: 8vh;

      @include forTablet {
        font-size: 2rem;
        
      }

      @include forPc {
        font-size: 2.8rem;

      }
    }

    & .rrss-container {
      display: flex;

      & img {
        width: 40px;
        margin: 10px;

        @include forTablet {
          width: 60px;
          margin: 20px;
        }

        @include forPc {
          width: 60px;
          margin: 20px;
        }
      }

      & .static {
        position: absolute;
        background-color: $fourthBgColor;
        cursor: pointer;

        &:hover {
          opacity: 0;
        }
      }

      & .rotate {
        &:hover {
          animation: bounce 0.8s;
        }
        @keyframes bounce {
          0%,
          20%,
          60%,
          100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
          }

          40% {
            -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
          }

          80% {
            -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
          }
        }
      }
    }
  }
}

.contact-container_copy {
  background-color: $fourthBgColor;
  font-family: $mainFont;
  font-size: 0.7rem;
  position: relative;
  width: 100%;


  @include forTablet {
    font-size: 0.9rem;
  }

  @include forPc {
    font-size: 1rem;
  }
}
