.projects-container {
  color: $darkFontColor;
  padding-top: 10vh;
  height: 100vh;

  @include forTablet {
  }

  @include forPc {
    &:hover .projectItem-container {
      transform: translateX(-40px);
    }
  }

  & .projects-title {
    font-size: 1rem;
    font-family: $menuFont;
    text-align: right;
    padding-right: 8vw;
    word-spacing: 10px;
    letter-spacing: 2px;

    @include forTablet {
    }

    @include forPc {
      font-size: 1.3rem;
    }
  }

  .projectItem-container {
    width: 100vw;
    padding-top: 5vh;
    padding-left: 10vw;
    padding-right: 10vw;

    @include forTablet {
      padding-left: 10vw;
      padding-right: 10vw;
    }

    @include forPc {
      padding-top: 100px;
      transition: transform 0.5s linear;
      width: 70vw;
      padding-left: 0vw;
      padding-right: 0vw;
    }

    & .projectItem-image {
      & img {
        width: 70vw;

        @include forTablet {
          width: 60vw;
        }

        @include forPc {
          width: 40vw;
        }
      }
    }

    & .projectItem-details {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding-top: 30px;

      @include forTablet {
        padding-top: 50px;
      }

      @include forPc {
        text-align: left;
      }

      & h4 {
        font-size: 0.8rem;
        padding-bottom: 10px;
        font-family: $mainFont;
        letter-spacing: 1px;

        @include forTablet {
          font-size: 1rem;
          letter-spacing: 2px;
          padding-bottom: 30px;
        }

        @include forPc {
          font-size: 1.3rem;
        }
      }

      & p {
        font-size: 0.7rem;
        margin-bottom: 20px;
        text-align: justify;
        font-family: $mainFont;
        letter-spacing: 2px;

        @include forTablet {
          font-size: 0.85rem;
          margin-bottom: 25px;
        }

        @include forPc {
          font-size: 1.1rem;
          margin-bottom: 30px;
          padding-right: 5%;
        }
      }

      .projectItem-tags {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 30px;

        @include forTablet {
          margin-bottom: 20px;
        }

        @include forPc {
          margin-bottom: 30px;
          justify-content: left;
        }

        & li {
          white-space: nowrap;
          font-family: $mainFont;
          background-color: #626262;
          color: white;
          border: solid 1px $darkFontColor;
          border-radius: 2px;
          margin: 3px;
          line-height: 20px;
          display: inline-block;
          padding-left: 15px;
          padding-right: 10px;
          font-weight: 500;
          text-align: right;
          font-size: 0.6rem;

          @include forTablet {
            font-size: 0.8rem;
            line-height: 40px;
            margin: 5px;
          }

          @include forPc {
            font-size: 1.1rem;
            height: 40px;
            line-height: 40px;
          }

          & span {
            background-color: #ffff;
            border: 1px solid #ffff;
            border-radius: 3px 3px 3px 3px;
            display: inline-block;
            height: 4px;
            left: -5px;
            position: relative;
            top: -1px;
            vertical-align: middle;
            width: 4px;
            z-index: 10;
            margin-right: 2px;

            @include forTablet {
              border-radius: 5px 5px 5px 5px;
              height: 7px;
              width: 7px;
              margin-right: 5px;
            }
          }
        }
      }

      & .projectItem-links {
        display: flex;
        justify-content: space-around;
        font-family: $mainFont;
        font-size: 0.7rem;

        @include forTablet {
          font-size: 1rem;
        }

        @include forPc {
          font-size: 1.2rem;
        }

        & .button-link {
          background-color: $darkFontColor;
          text-align: center;
          display: inline-block;
          position: relative;
          text-decoration: none;
          color: #fff;
          text-transform: capitalize;
          padding: 10px 0px;
          width: 90px;
          border-radius: 3px;
          overflow: hidden;
          transition: all 0.2s linear 0s;
          box-shadow: 0 0 5px 0 #1e1f22;
          border: 1px solid;

          @include forTablet {
            width: 135px;
            padding: 20px 0px;
          }

          @include forPc {
            width: 150px;
            padding: 20px 0px;
          }

          &:before {
            content: '🔗';
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: -50px;
            width: 40px;
            height: 100%;
            background-color: #fff;
            transition: all 0.2s linear 0s;
            text-align: center;

            @include forTablet {
              font-size: 1.3rem;
              width: 50px;
            }

            @include forPc {
              font-size: 1.4rem;
            }
          }

          &:hover {
            text-indent: -40px;

            @include forTablet {
              text-indent: -50px;
            }

            &:before {
              right: 0;
              text-indent: 0px;
            }
          }
        }
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: column;
  background-color: $thirdBgColor;

  @include forTablet {
  }

  @include forPc {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 400px;
  }
}

.row-reverse {
  display: flex;
  flex-direction: column;
  background-color: $firstBgColor;
  color: $ligthFontColor;

  @include forTablet {
  }

  @include forPc {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    left: 420px;
  }
}
