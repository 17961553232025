.app-container {
  text-align: center;
}

.scroll-snapping {
  scroll-snap-type: y proximity;
  overflow: auto;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  @include forTablet {
    scroll-snap-type: y mandatory;
  }
}

.scroll-area {
  scroll-snap-align: center;
  height: 100vh;
}
