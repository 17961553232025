.start-container {
  color: $ligthFontColor;
  height: 100vh;
  background-image: url(../../assets/images/backgrounds/startBg.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  @include forTablet {
  }

  @include forPc {
  }

  & h2 {
    font-size: 1rem;
    font-family: $menuFont;
    text-align: right;
    word-spacing: 10px;
    letter-spacing: 2px;
    padding-right: 8vw;
    padding-top: 10vh;

    @include forTablet {
      font-size: 1rem;
      padding-top: 8vh;
    }

    @include forPc {
      font-size: 1.3rem;
    }
  }

  & .start-container_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20vw;
    padding-right: 20vw;
    height: 100vh;
    padding-top: 12vh;

    @include forTablet {
      padding-top: 12vh;
    }

    @include forPc {
      flex-direction: row;
      padding-left: 18vw;
      padding-right: 14vw;
      padding-top: 0;
      padding-bottom: 10vh;
    }
  }

  & .lauraPhoto-container {
    & img {
      height: 40vh;
      filter: brightness(1.1);

      @include forTablet {
        height: 40vh;
      }

      @include forPc {
        height: 50vh;
      }
    }
  }

  & .lauraText-container {
    font-family: $typewriterFont;
    padding-top: 5vh;
    font-size: 1rem;
    color: $thirdBgColor;

    @include forTablet {
      font-size: 1.5rem;
      padding-top: 8vh;
    }

    @include forPc {
      margin-left: 15vw;
      margin-right: 0;
      font-size: 2rem;
      padding-top: 0;
    }
  }
}
