//DEVICES
$tablet: 768px;
$pc: 1024px;

@mixin forTablet {
  @media all and (min-width: $tablet) {
    @content;
  }
}
@mixin forPc {
  @media all and (min-width: $pc) {
    @content;
  }
}

//FONT-STYLE
@mixin text-capitalize {
  &::first-letter {
    text-transform: capitalize;
  }
}
@mixin text-uppercase {
  text-transform: uppercase;
}
