.about-container {
  background-color: $secondBgColor;
  color: $darkFontColor;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @include forTablet {
  }

  @include forPc {
  }

  & .about-container_text {
    & h2 {
      font-size: 1rem;
      font-family: $menuFont;
      text-align: right;
      padding-right: 8vw;
      padding-top: 10vh;
      word-spacing: 10px;
      letter-spacing: 2px;

      @include forTablet {
        font-size: 1rem;
        padding-top: 8vh;
      }

      @include forPc {
        font-size: 1.3rem;
      }
    }

    & p {
      font-family: $mainFont;
      font-size: 0.8rem;
      margin-top: 3vh;
      text-indent: 15vw;
      text-align: justify;
      line-height: 1rem;
      letter-spacing: 1px;
      padding-right: 8vw;
      padding-left: 10vw;
      color: $ligthFontColor;

      & .underline {
        text-decoration: underline dotted black;
      }

      @include forTablet {
        font-size: 1rem;
        word-spacing: 6px;
        letter-spacing: 2px;
        line-height: 1.2rem;
        text-indent: 100px;
      }

      @include forPc {
        font-size: 1.2rem;
        word-spacing: 10px;
        letter-spacing: 2px;
        line-height: 1.8rem;
        text-indent: 200px;
        margin-top: 30px;
        padding-left: 15vw;
      }
    }
  }

  .about-container_skills {
    padding-top: 8vh;

    @include forTablet {
      padding-top: 8vh;
    }

    @include forPc {
    }

    & .about-container_skills__title {
      & h2 {
        font-size: 1rem;
        font-family: $menuFont;
        text-align: right;
        padding-right: 8vw;
        word-spacing: 10px;
        letter-spacing: 2px;

        @include forTablet {
          font-size: 1rem;
        }

        @include forPc {
          font-size: 1.3rem;
        }
      }
    }

    & .about-container_skills__skills {
      & .about-skills_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        padding-top: 5vh;
        padding-left: 8vw;
        padding-right: 8vw;

        @include forTablet {
          padding-top: 10vh;
        }

        @include forPc {
          flex-direction: row;
          flex-wrap: none;
          padding-top: 10vh;
          padding-left: 15vw;
        }

        & .about-skills_item {
          list-style-type: none;
          font-family: $menuFont;
          padding: 5px;
          transition: transform 0.6s linear;
          cursor: pointer;

          @include forTablet {
            padding: 10px;
          }

          @include forPc {
            padding: 10px;
          }

          &:hover {
            transform: scale(1.5);
          }

          & img {
            width: 30px;

            @include forTablet {
              width: 40px;
            }

            @include forPc {
              width: 50px;
            }
          }

          & p {
            font-weight: 700;
            font-size: 0.6rem;
            text-align: center;

            @include forTablet {
              font-size: 1rem;
            }

            @include forPc {
              font-size: 1rem;
            }
          }
        }
      }
    }

    & .about-container_skills__evolution {
      padding-top:5vh;
      & img {
        width: 150px;


        @include forTablet {
          width: 150px;
          margin-top: 15vh;
        }

        @include forPc {
          width: 150px;
          margin-top: 15vh;
        }
      }
    }
  }

  & .about-container_cv {
    font-family: $mainFont;

    & a {
      text-decoration: none;
      color: $darkFontColor;
      font-size: 1.2rem;
      padding: 5px;
      border: dotted 2px $darkFontColor;
      font-weight: 600;

      @include forTablet {
        font-size: 1rem;
      }

      @include forPc {
        font-size: 1rem;
        padding: 10px;
      }
    }
  }
}
