.menu-container {
  background-color: $menuBgColor;
  color: $ligthFontColor;
  font-family: $menuFont;
  font-size: 0.8rem;
  position: fixed;
  width: 100%;
  -webkit-box-shadow: 4px 0px 10px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 0px 10px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 0px 10px -5px rgba(0, 0, 0, 0.75);
  z-index: 99;

  @include forTablet {
    font-size: 1.5rem;
  }

  @include forPc {
    width: 11vw;
    height: 100vh;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    opacity: 0.7;
  }

  & .menu-container_list {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    margin-top: 2vh;
    margin-bottom: 2vh;
    cursor: pointer;

    @include forTablet {
    }

    @include forPc {
      flex-direction: column;
      line-height: 45px;
    }
  }

  & .menu-container_list__item {
    display: block;
    position: relative;

    &:first-child a {
      border-right: 10px solid black;
      padding-right: 5px;
    }
    &:nth-child(2) a {
      border-right: 10px solid $secondBgColor;
      padding-right: 5px;
    }
    &:nth-child(3) a {
      border-right: 10px solid $thirdBgColor;
      padding-right: 5px;
    }
    &:last-child a {
      border-right: 10px solid $fourthBgColor;
      padding-right: 5px;
    }
  }
}

nav li a:after {
  content: '';
  height: 100%;
  right: 0;
  top: 0;
  width: 0px;
  position: absolute;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  z-index: -99;
  opacity: 0.3;
}

nav li a:active {
  border-bottom: white 2px solid;
}

nav li a:hover:after {
  width: 100%;
}

nav li:first-child a:after {
  background: black;
}
nav li:nth-child(2) a:after {
  background: $secondBgColor;
}
nav li:nth-child(3) a:after {
  background: $thirdBgColor;
}
nav li:last-child a:after {
  background: $fourthBgColor;
}
