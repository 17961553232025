//COLORS

$menuBgColor: #29292c;

$firstBgColor: #29292c;
$secondBgColor: #ce757b;
$thirdBgColor: #fdf8ed;
$fourthBgColor: #dfcd79;


$ligthFontColor: $thirdBgColor;
$darkFontColor: $firstBgColor;

//TYPOGRAPHY

$mainFont: montserrat;
$menuFont: droid;
$typewriterFont: droid;

@font-face {
  src: url(../../assets/fonts/DroidSansMono.ttf);
  font-family: droid;
}

@font-face {
  src: url(../../assets/fonts/Montserrat-Regular.ttf);
  font-family: montserrat;
}
